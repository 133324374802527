// =================================== FONTS
@mixin font-awesome($icon) {
  @include fa-icon();
  content: $icon;
}

// =================================== Helpers
@mixin object-fit($type, $position: '') {
    object-fit: $type;
    $polyfill: 'object-fit: #{$type}';

    @if $position != '' {
        object-position: $position;
        $polyfill: $polyfill +'; object-position: #{$position}';
    }

    font-family: $polyfill;
}

@mixin linkcolors($color, $visited: '') {
    @if $visited == '' {
        $visited: $color;
    }

    &:link { color: $color; }
    &:visited { color: $visited; }
}

@mixin clear-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

// =================================== OMEGAS
@mixin nth-omega($selector, $grid: $neat-grid) {
  $_grid-gutter: _retrieve-neat-setting($grid, gutter);

  &:nth-of-type(#{$selector}) {
    margin-#{_neat-opposite-direction($grid)}: $_grid-gutter;
  }
  &:nth-of-type(#{$selector}+1) {
    clear: _neat-float-direction($grid);
  }
  &:last-child {
    margin-#{_neat-opposite-direction($grid)}: $_grid-gutter;
  }
}
