@import 'bourbon';
@import 'neat';
@import 'typey';
@import 'reset';
@import 'font-awesome';
@import 'tiny-slider';
@import 'iconfont';
@import 'mixins';
@import 'burger';
@import 'variables';

@keyframes blink-message {
    70% { opacity: 0; }
}

/**
 * Global styles
 */
html {
    @include define-type-sizing;
    @include typeface(sans-serif);
    color: $color-text;
    min-height: 100%;
}

body.mobile-nav-active {
    overflow: hidden;
}

p {
    @include margin(1 0);
}

img {
    width: 100%;
}

h1 {
    @include typeset(heading-1);
    color: $color-primary;
}

h2 {
    @include typeset(heading-2);
    color: $color-primary;

    i.fa {
        color: $color-text;
        @include margin-right(0.5);
    }

    &.up {
        @include typeset(heading-2-up);
        color: $color-text;
    }
}

h3 {
    @include typeset(heading-3);
    color: $color-text;
}

h4 {
    @include typeset(heading-4);
    color: $color-text;
}

a {
    &:link,
    &:visited { color: $color-primary; }
    &:hover { color: darken($color-primary, 15%); }
}

p {
    &.empty {
        font-weight: weight(semibold);
        font-size: font-size(ml);
        &:before {
            @include font-awesome($fa-var-info-circle);
            @include margin-right(0.5);
        }
    }
}

ul {
    @include margin(0.5 0 0.5 1.5);
}

/**
 * Container
 */
.container {
    @include grid-container;
    @include margin(0 auto);
    max-width: $max-width;
}

/**
 * Table styles
 */
table {
    &.wishlist {
        @include margin-bottom(1);
    }
    &.overview-table,
    &.cart {
        @include margin-top(1);
        border-collapse: collapse;

        tbody {
            tr:last-child td { border-bottom: none; }
        }
        th,
        td {
            @include padding(0.5);
            border-bottom: 1px solid $color-border;
            vertical-align: middle;

            &.actions,
            &.visual {
                text-align: center;
                img {
                    max-width: 40px;
                }
            }

            &.price-col,
            &.price {
                text-align: right;
            }

            a { text-decoration: none; }
        }
        thead {
            tr > th,
            tr > td {
                font-weight: weight(semibold);
                background: $color-bg-light;
                border-bottom: 5px solid darken($color-bg-light, 15%);
            }
        }

        @include grid-media($only-mobile) {
            .image-col,
            .visual,
            .price-unit,
            .form,
            .sku { display: none; }
        }
    }

    a.delete {
        font-size: font-size(ml);
        color: $color-error;
        text-decoration: none;
        span { display: none; }
        &:before {
            @include font-awesome($fa-var-trash-o);
        }
    }

    .quantity-col {
        width: 90px;
    }

    .form {
        .input {
            padding: 0;
            position: relative;
            button {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                background: $color-primary;
                color: white;
                border-radius: 0;
                border: none;
                background-color: none;
                display: block;
                @include padding(0 0.5);
                span { display: none; }
                &:before {
                    @include font-awesome($fa-var-refresh);
                }
                &:hover {
                    background: $color-primary-hover;
                }
            }
        }
        input[type="number"],
        input[type="text"] {
            @include padding(0.3);
        }
    }

    &.total {
        @include margin-bottom(1);
        border-collapse: collapse;
        background: $color-bg-light;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;

        tr.subtotal td {
            background: white;
        }

        td {
            @include padding(0.5);
        }
        .label {
            width: 80%;
            font-weight: weight(bold);
        }
        .subtotal .price,
        .total {
            text-align: right;
            font-size: font-size(mll);
        }
    }
}

/**
 * Messages
 */
%message-style-error {
    color: white;
    background-color: $color-error;
    &:before {
        @include font-awesome($fa-var-exclamation-triangle);
        @include margin-right(0.5);
    }
}

.message {
    @include padding(1);
    @include margin-bottom(1);
    @include grid-column(12);
    background: $color-primary;
    font-size: font-size(ml);
    color: white;

    &:before {
        @include font-awesome($fa-var-info-circle);
        @include margin-right(0.5);
    }

    &#noticeMessage {
        background-color: $color-primary-hover;
    }

    &#authMessage,
    &.error {
        @extend %message-style-error;
    }
}

/**
 * Button styles
 */
.button {
    @include typeset(button);
    @include padding(0.5 1);
    @include linkcolors(white);
    border-radius: 0;
    cursor: pointer;
    outline: 0;
    border: 0;
    display: inline-block;
    text-decoration: none;
    color: white;
    background: $color-primary;
    transition: 100ms ease-in-out;
    text-align: center;
    border: 2px solid $color-primary;

    &:hover {
        background: darken($color-primary, 15%);
        border: 2px solid darken($color-primary, 15%);
    }

    &.small {
        font-size: font-size(s);
        line-height: line-height(0);
        @include padding(0.7 0.8);
    }

    &.inverted {
        @include linkcolors($color-primary);
        background: none;
        color: $color-primary;
        border: 2px solid $color-primary;
        &:hover {
            color: white;
            background-color: $color-primary;
        }
    }

    &.point-right:after {
        @include margin-left(1);
        @include font-awesome($fa-var-long-arrow-right);
    }

    &.cart-add:before {
        @include font-awesome($fa-var-cart-plus);
        @include margin-right(0.5);
        font-size: font-size(mll);
    }

    &.gift-add:after {
        @include margin-left(1);
        @include font-awesome($fa-var-gift);
        font-size: font-size(mll);
    }

    &.fluid {
        display: block;
        width: 100%;
    }
}

.option-dropdown {
    @include clear-list;
    > li {
        user-select: none;
        $border-width: 2px;
        transition: border-color 100ms ease-in-out;
        @include padding(0.5 1);
        border: $border-width solid $color-border;
        position: relative;
        font-weight: weight(semibold);
        text-transform: uppercase;
        cursor: pointer;
        &:after {
            @include font-awesome($fa-var-angle-down);
            @include margin-left(0.5);
        }
        > ul {
            user-select: none;
            transition: border-color 100ms ease-in-out;
            display: none;
            background: white;
            border: $border-width solid $color-border;
            border-top: 0;
            position: absolute;
            left: -$border-width;
            right: -$border-width;
            z-index: 999;
            top: 43px;
            @include clear-list;

            > li .no-stock,
            > li > a {
                text-decoration: none;
                display: block;
                @include padding(0.5 0.5);
                border-top: 1px solid $color-border;

                &.active {
                    background-color: $color-bg-light;
                }
            }
            > li .no-stock {
                opacity: 0.7;
            }
        }
    }

    &.open > li {
        border-color: $color-primary;
        > ul {
            display: block;
            border-color: $color-primary;
        }
    }
}

.social-navigation {
    @include clear-list;
    > li { display: inline; }
    > li > a {
        @include margin-right(0.5);
        display: inline-block;
        color: white;
        .item-label { display: none; }
        transition: transform 100ms ease-in-out;
        &:hover {
            transform: scale(1.5);
        }
    }
}

header {
    @include grid-media($layout-grid-tablet-up) {
        @include margin-bottom(1);
    }

    nav.main {
        @extend .container;

        display: none;
        @include grid-media($layout-grid-tablet-up) {
            display: block;
        }
        ul {
            @include clear-list;
        }
        > ul {
            @include grid-column(1, $layout-grid);
            @include grid-media($layout-grid-desktop-up) {
                justify-content: space-between;
                display: flex;
            }

            border-top: 1px solid $color-border;
            border-bottom: 1px solid $color-border;
            text-align: center;
            > li {
                @include typeset(nav);
                display: inline;
                > a {
                    @include padding(0.8 1);
                    cursor: pointer;
                    display: inline-block;
                    text-decoration: none;
                    color: $color-text;
                    white-space: nowrap;
                    &:hover { color: $color-primary; }
                    &.highlight {
                        background: $color-primary;
                        color: white;
                        transition: background 100ms ease-in-out;
                        &:hover {
                            background: $color-primary-hover;
                        }
                    }
                }

                &.has-subnav {
                    position: relative;

                    > a:after {
                        @include font-awesome($fa-var-angle-right);
                        min-width: 20px;
                        text-align: center;
                    }

                    &.open {
                        background: lighten($color-border, 3%);
                        //transition: background 100ms ease-in-out;
                        > .subnav {
                            visibility: visible;
                            //transform: translateY(0em);
                        }
                        > a:after {
                            @include font-awesome($fa-var-angle-down);
                        }
                    }

                    > .subnav {
                        //transform: translateY(-10px);
                        //transition: all 100ms ease-in-out, visibility 100ms;
                        visibility: hidden;
                        background: lighten($color-border, 3%);
                        min-width: 200px;
                        top: 57px;
                        text-align: left;
                        z-index: 500;
                        position: absolute;
                        @include padding(0.5 0);
                        > li a {
                            color: $color-text;
                            text-decoration: none;
                            display: inline-block;
                            @include padding(0.3 1);
                        }
                    }
                }
            }
        }
    }
    .top {
        background: $color-primary;
        @include padding(0.2 0);

        .top-items { }
        .top-items > li {
            color: white;
            text-transform: lowercase;
            @include font-size(s);
            @include margin(0 0.8 0 0);

            &:before { @include margin-right(0.3); }
            &.shipping:before {
                @include font-awesome($fa-var-paper-plane-o);
            }
            &.secure:before {
                @include font-awesome($fa-var-lock);
            }

            &:last-child { margin: 0; }

            &.mobile-menu {
                .mobile-menu-button {
                    outline: 0;
                    position: absolute;
                    right: 0;
                    top: 9px;
                    z-index: 999;
                    position: absolute;
                    padding: 0;
                    display: inline-block;
                    background-color: $color-primary;
                    border: none;
                    .burger-icon {
                        @include burger(25px, 3px, 5px, white, 10px);
                    }

                    &.is-active .burger-icon {
                        @include burger-to-cross;
                    }
                }
            }
        }

        .top-items,
        .social-navigation {
            @include clear-list;
            @include grid-column(1, $layout-grid);
            position: relative;

            &.social-navigation { display: none; }

            > li {
                display: inline-block;
                @include padding(0.3 0);
            }

            @include grid-media($layout-grid-tablet-up) {
                @include grid-column(6);
                &.social-navigation { display: block; }
                &.top-items {
                    text-align: right;
                    > li {
                        @include margin(0 0 0 1);
                        float: none;
                        &.mobile-menu { display: none; }
                    }
                }
            }
        }
    }

    .middle {
        @include padding(1 0);
        .service,
        .search,
        .logo {
            @include grid-column(1, $layout-grid);
            text-align: center;
            img { max-width: 220px; }
            @include grid-media($layout-grid-tablet-up) {
                text-align: left;
                @include grid-column(4);
                &.service { text-align: right; }
            }
        }

        .search {
            @include margin-top(0.5);
            text-align: center;
            position: relative;
            input[type="text"] {
                width: 100%;
                border: none;
            }
            .search-suggestions {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom:0;
                text-align: left;
                @include padding(0.4 0.5);
            }
            button {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                background: none;
                color: $color-primary;
                &:before { @include font-awesome($fa-var-search); }
                span { display: none; }
                cursor: pointer;
            }
        }

        .service {
            @include margin-top(0.6);
            ul {
                @include clear-list;
                > li {
                    display: inline;
                    > a {
                        display: inline-block;
                        text-decoration: none;
                        @include font-size(l);
                        @include margin-left(0.5);
                        color: $color-primary;
                        transition: transform 100ms ease-in-out;
                        span { display: none; }
                        &.account:before {
                            @include font-awesome($fa-var-user-o);
                        }
                        &.cart-wishlist,
                        &.cart-order {
                            position: relative;
                            @include icon(shop);
                            span {
                                position: absolute;
                                bottom: -3px;
                                right: -3px;
                                background: $color-primary;
                                color: white;
                                font-size: 8px;
                                width: 18px;
                                height: 18px;
                                line-height: 18px;
                                border-radius: 18px;
                                text-align: center;
                                display: block;
                            }
                        }
                        &.wishlist:before {
                            @include font-awesome($fa-var-heart-o);
                        }
                        &:hover {
                            color: $color-primary-hover;
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }
}

/**
 * Main
 */
.main-wrapper {
    #main {
        min-height: 350px;
    }

    &.no-sidebar {
        #main {
            @include grid-column(1, $layout-grid);
        }
    }

    &.has-sidebar {
        @include grid-container;

        .sidebar,
        #main { @include grid-column(1, $layout-grid); }

        @include grid-media($layout-grid-tablet-up) {
            .sidebar {
                @include grid-column(3);
            }
            #main {
                @include grid-column(9);
            }
        }
    }

    #above-content {
        @include padding-bottom(2);
    }
}

/**
 * BANNERS
 */
.tns-outer {
    position: relative;
    @include grid-collapse;
    > .tns-nav {
        text-align: center;
        z-index: 200;
        position: absolute;
        bottom: .5rem;

        @include margin-bottom(0.5);
        button {
            display: inline-block;
            border: 0;
            outline: none;
            border: 0;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin: 0 3px;
            background: #eee;
            &.tns-nav-active { background: #ccc; }
        }
        @include grid-media($only-mobile) {
            left: 50%;
            transform: translateX(-50%);
            button {
                width: 10px;
                height: 10px;
                border-radius: 10px;
            }
        }
        @include grid-media($layout-grid-tablet-up) {
            right: 3rem;
            button {
                background: rgba(white, 0.3);
                &.tns-nav-active { background: white; }
            }
        }
    }
}

#banners {
    .banner > .banner-wrapper {
        @include grid-container;
        position: relative;
    }


    @include grid-media($layout-grid-tablet-up) {
        .banner > .banner-wrapper {
            .info {
                @include grid-column(4);
                overflow: hidden;
                max-height: 100%;
                > .inner {
                    @include padding(2 1.5 0 2);
                }
                .button-wrapper {
                    @include padding-top(1);
                }
            }
            .visual {
                @include grid-column(8);
                display: block;
                max-height: 425px;
                img {
                    @include object-fit(cover, 50% 70%);
                    width: 100%;
                    max-height: 425px;
                }
            }
        }
    }
    @include grid-media($only-mobile) {
        .banner > .banner-wrapper {
            .info {
                .banner-body { display: none; }
                h2 {
                    color: white;
                    font-size: font-size(mll);
                    text-shadow: -1px 1px 1px rgba(0,0,0,0.5);
                }
                position: absolute;
                text-align: center;
                top: 50%;
                transform: translateY(-55%);
                left: 1rem;
                right: 1rem;
                z-index: 100;
            }
            .visual {
                @include grid-column(1, $layout-grid);
            }
        }
    }
}

/**
 * Homepage regions
 */
#hp-blocks {
    @include grid-container;
    @include margin-top(1);
    @include grid-collapse;

    border-bottom: 1px solid $color-border;

    .hp-block {
        @include grid-column(1, $layout-grid);
        transition-duration: 0;
        position: relative;
        @include margin-bottom(1);
        a { color: $color-text; }

        img {
            max-height: 245px;
            height: 100%;
            width: 100%;
            @include object-fit(cover, 50% 20%);
        }
        .label {
            @include typeset(blocklabel);
            @include padding(0.3 0.5);
            position: absolute;
            bottom: 20px;
            left: 20px;
            background: rgba(white, 0.8);
            span { color: $color-primary; @include margin-left(0.2); }
        }
    }

    @include grid-media($layout-grid-tablet-up) {
        border: none;
        .hp-block {
            @include grid-column(4);
            transition: transform 100ms ease-in-out;
            &:hover {
                transform: translate(2px, -2px);
            }
            img {
                max-height: 504px;
                object-position: 50% 50%;
            }
            &:nth-child(3),
            &:nth-child(1) {
                max-height: 504px;
                overflow: hidden;
            }
        }
    }
}

/** blocks */
.block-action-banner {
    position: relative;
    .body {
        position: absolute;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);
        left: 0;
        right: 0;

        > h3 {
            @include typeset(banner-title);
            color: white;
        }
    }
}

.block-instagram-feed {
    @include margin(2 0 0 0);
    @include padding(2 0 0 0);
    text-align: center;
    border-top: 1px solid $color-border;
    h2 {
        color: $color-text;
        text-transform: uppercase;
        a {
            color: $color-text;
            text-decoration: none;
        }
        &:before {
            @include font-awesome($fa-var-instagram);
            @include margin-right(1);
        }

        @include grid-media($layout-grid-tablet-up) {
            font-size: font-size(xl);
        }
    }
    #instafeed {
        @include grid-container;
        @include margin-top(2);
        &.loading {
            background: url('../img/loading.svg') no-repeat center center;
            background-size: 80px 80px;
            min-height: 200px;
        }
        > a {
            height: 0;
            overflow: hidden;
            position: relative;

            @include grid-column(2, $gutterless-grid);
            padding-bottom: calc(#{_neat-column-width($gutterless-grid, 2)});

            @include grid-media($gutterless-grid-tablet-up) {
                @include grid-column(2, $gutterless-grid-tablet-up);
                padding-bottom: calc(#{_neat-column-width($gutterless-grid-tablet-up, 2)});
            }
            img {
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                @include object-fit(cover, 50% 50%);
                transition: transform 100ms ease-in-out;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.block-newsletter-form {
    border-top: 1px solid $color-border;
    .block-inner {
        @include padding(3 0);
        @extend .container;
        @include grid-container;

        p { margin: 0; padding: 0; color: $color-text-light; }
        h2 { color: $color-text; }

        .form,
        .info {
            @include grid-column(1, $layout-grid);
            @include margin-bottom(1);
            @include grid-media($layout-grid-tablet-up) {
                @include grid-column(6);
                @include margin-bottom(0);
            }
        }
        .form {
            position: relative;
            .button {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                font-size: font-size(mll);
                @include padding(0 1);
            }
        }
    }
}

.block-reyskens-specialiteiten {
    @include grid-column(1, $layout-grid);
    @include padding(1);
    @include margin-bottom(1);
    background-color: $color-bg-light;

    .block-inner-wrapper {
        > h4 {
            font-size: font-size(l);
            color: $color-primary;
            font-weight: weight(semibold);
            line-height: line-height(1.7);
            span {
                display: block;
                font-weight: weight(normal);
            }
        }

        > ul {
            @include clear-list;
            li {
                display: inline;
                > a {
                    @include margin-right(0.5);
                    @extend .button;
                    @extend .button.inverted;
                    @extend .button.point-right;
                    color: $color-primary;
                    display: block;
                    text-transform: uppercase;
                    text-decoration: none;
                    @include margin-bottom(1);
                }
            }
        }
    }

    @include grid-media($layout-grid-tablet-landscape-up) {
        background: $color-bg-light url('../img/logo_notxt.svg') no-repeat -10% 50%;
        background-size: 50%;

        .block-inner-wrapper {
            @include grid-column(6);
            @include grid-push(6);

            > ul li > a {
                display: inline-block;
            }
        }
    }
}

.block-flagged,
.block-image-with-text {
    @include grid-container;
    @include margin-bottom(1);
    .body,
    .visual { @include grid-column(1, $layout-grid); }
    .body { @include padding(1 0); }

    .articles {
        article {
            @include grid-column(2, $only-mobile);
            @include padding-bottom(1);
            @include margin-bottom(1);
        }
    }

    #below-content & .block-inner {
        @extend .container;
    }

    @include grid-media($layout-grid-tablet-landscape-up) {
        .visual,
        .body {  @include grid-column(6); }
        .body { @include padding(1 2); }

        .articles {
            @include grid-container;
            @include grid-collapse;
            > article {
                @include grid-column(6);
                border-bottom: 1px solid $color-border;
                img {
                    float: left;
                    width: 50%;
                }

                a > .info {
                    @include padding-top(1);
                    float: left;
                    width: 50%;
                    text-align: left;
                    * {
                        font-size: font-size(m) !important;
                        line-height: line-height(0.8);
                        text-align: left;
                    }
                }

                &:nth-child(n+3) {
                    border-bottom: none;
                }
            }
        }
    }
}

.block-collection-flagged {
    @include padding(2 0);
    background: $color-bg-contrast;

    h2 {
        @include grid-column(2, $layout-product-grid);
        @include padding(0 0 1 0);
    }

    .articles {
        @include grid-container;
        > article {
            @include grid-column(1, $layout-product-grid);
        }
    }

    @include grid-media($layout-grid-tablet-up) {
        @include margin-top(1);
        @include margin-bottom(2);
        .articles > article {
            @include grid-column(3);
        }

        &:first-of-type {
            margin-bottom: 0;
        }
    }
}

.block-container {
    &.landing_bottom,
    &.landing_top {
        @include grid-container;
        @include grid-collapse;
    }
    &.landing_top {
        @include margin-bottom(1);
        .block {
            @include grid-column(1, $layout-grid);
            background-color: $color-bg-light;
            a {
                text-decoration: none;
                @include linkcolors($color-text);
                &:hover {
                    .body h4 {
                        transform: translate(5px, -5px);
                    }
                    .visual img {
                        transform: scale(1.1);
                    }
                }
            }
            .visual {
                overflow: hidden;
                img {
                    transition: transform 100ms ease-in-out;
                }
            }
            .body {
                @include padding(1);
                position: relative;

                h4 {
                    @extend .button;
                    transition: transform 100ms ease-in-out;
                    position: absolute;
                    top: -1.5rem;
                }
            }
        }
        @include grid-media($layout-grid-tablet-up) {
            &.block-count-4 .block {
                @include grid-column(3);
            }
            &.block-count-3 .block {
                @include grid-column(4);
            }
            &.block-count-2 .block {
                @include grid-column(6);
            }
        }
    }
}

/**
 * Seo region
 */
#seo-region {
    @include margin(2 0);
    .block {
        > h4 {
            @include typeset(heading-2);
            color: $color-primary;
        }
        @include margin(1 0);
        @include padding(1.5);
        background-color: $color-bg-light;

        p:last-of-type { padding-bottom: 0; margin-bottom: 0;}
    }
}

/**
 * Product
 */
article.product {
    &.teaser {
        @include margin-top(0.5);
        @include margin-bottom(0.5);

        max-height: 350px;

        > a {
            position: relative;
            outline: 0;
            display: block;
            background: white;
            text-decoration: none;
            transition: transform 100ms ease-in-out;
            h3 {
                font-weight: weight(semibold);
                font-size: font-size(ml);
            }

            .percent-bubble {
                $size: 60px;
                position: absolute;
                top: 10px;
                right: 10px;
                background: $color-percentage-bubble;
                color: white;
                width: $size;
                height: $size;
                line-height: $size;
                border-radius: $size/2;
            }

            > .info {
                text-align: center;
                @include padding-bottom(1);
            }
            &:hover {
                transform: translate(5px, -5px);
            }
        }
    }

    &.full {
        @include grid-container;
        @include grid-collapse;

        .info,
        .visual {
            @include grid-column(1, $layout-grid);
        }

        h2 {
            @include margin-bottom(1);
        }

        .sale {
            @include margin-bottom(1);
            position: relative;
            .percent-bubble {
                $size: 70px;
                right: 0;
                top: -1.5em;
                width: $size;
                height: $size;
                text-align: center;
                line-height: $size;
                border-radius: $size/2;
                font-size: font-size(l);
                position: absolute;
            }
        }

        .price {
            @include font-size(l);
            @include margin-bottom(1);
        }

        @include grid-media($layout-grid-tablet-up) {
            .visual {
                @include grid-column(6);
                @include padding-right(0.5);
            }
            .info {
                @include grid-column(6);
                @include padding-left(0.5);
            }
        }
    }

    .visual {
        .images {
            @include margin-top(1);
            @include grid-container;
            @include grid-collapse;

            .image {
                @include margin-bottom(1);
                @include grid-column(1, $layout-product-grid);
            }

            @include grid-media($layout-grid-tablet-up) {
                > .image {
                    @include grid-column(3);
                }
            }
        }

        .images > .image,
        .image-large {
            border: 2px solid $color-border;
        }
    }

    .top {
        @include margin-bottom(1);
        border-bottom: 1px solid $color-border;
    }

    .actions {
        @include grid-container;
        @include margin-bottom(1);

        .add-to-wishlist {
            @extend .button;
            @extend .button.inverted;
            @include padding-left(0.7);
            @include padding-right(0.7);
            font-size: font-size(ml);
        }

        .size-select {
            display: inline-block;
        }

        .button,
        .add-to-wishlist,
        .size-select {
            @include margin-right(0.2);
            @include margin-bottom(0.5);
        }

        .select-size-error-message {
            @include padding(0.5);
            @include margin-bottom(1);
            @extend %message-style-error;
            text-align: center;
            position: relative;
            animation: blink-message 500ms linear;

            &:after {
                $size: 10px;
                position: absolute;
                content: "";
                bottom: -$size;
                left: 80px;
                width: 0;
                height: 0;
                border-left: $size solid transparent;
                border-right: $size solid transparent;
                border-top: $size solid $color-error;
            }

            display: none;
            &.visible {
                display: block;
            }
        }

        @include grid-media($layout-grid-tablet-up) {
            .select-size-error-message {
                max-width: 50%;
            }

            .button,
            .add-to-wishlist,
            .size-select { @include margin-right(0.2); margin-bottom: 0; }
        }
    }

    .no-stock-message {
        @include margin-bottom(1);
        background: $color-bg-light;
        @include padding(1);
    }

    .price {
        color: black;
    }

    .old-price,
    .price {
        font-size: font-size(mll);
        font-weight: weight(semibold);
    }

    .sale {
        .percent-bubble {
            background-color: $color-percentage-bubble;
            color: white;
        }

        .price,
        .old-price {
            display: inline;
        }
        .price {
            color: $color-primary;
        }
        .old-price {
            @include margin-left(0.5);
            color: $color-text;
            text-decoration: line-through;
        }
    }

    .description {
        border-bottom: 1px solid $color-border;
        @include padding-bottom(1);
    }

    .attribute-wrapper {
        @include padding(1 0 0 0);
        @include margin-bottom(1);
        table.attribute-list {
            .label {
                font-weight: weight(semibold);
            }
            a {
                text-decoration: none;
            }
        }
    }

    .model-wrapper {
        h3 {
            @include margin-bottom(0.5);
            color: $color-primary;
            &:before {
                @include font-awesome($fa-var-adjust);
                @include margin-right(0.5);
                color: $color-text;
            }
        }
        .model-select {
            @include clear-list;
            @include grid-container;
            @include grid-collapse;
            > li {
                @include grid-column(2);
                @include margin-bottom(1);
                > a {
                    display: block;
                    border: 1px solid $color-border;
                    transition: border-color 100ms ease-in-out;
                    &:hover {
                        border-color: $color-primary;
                    }

                    &.active {
                        border-color: $color-primary;
                    }
                }
            }
        }
    }
}

.action-links {
    @include grid-container;
    @include grid-collapse;
    @include margin-top(2);

    border-top: 1px solid $color-border;
    @include padding-top(1);

    .articles-per-page,
    .sort-links {
        @include grid-column(1, $layout-grid);
        font-weight: weight(semibold);
        @include margin-bottom(0.5);
        > li {
            color: $color-primary;
            display: inline-block;
            &.label { @include margin-right(0.5); }
            > a {
                display: inline-block;
                border: 1px solid $color-primary;
                text-decoration: none;
                @include padding(0 0.2);
                &:hover {
                    background-color: $color-primary;
                    color: white;
                }
            }
        }
    }

    @include grid-media($layout-grid-tablet-up) {
        padding-top: 0;
        border-top: 0;
        .articles-per-page,
        .sort-links {
            @include grid-column(6);
        }
        .sort-links {
            text-align: right;
        }
    }
}

.products-list {
    @include grid-container;
    @include grid-collapse;
    @include padding-top(1);
    article {
        @include grid-column(1, $layout-product-grid);
    }

    @include grid-media($layout-grid-tablet-up) {
        article {
            @include grid-column(4);
        }
    }
}

.sidebar-nav,
.facet {
    //@include margin-bottom(1);
    @include padding(1 0);
    border-bottom: 1px solid $color-border;
    max-width: 90%;
    &:first-child { padding-top: 0; }
    &:last-child { border-bottom: 0; }
    > h3 {
        font-size: font-size(lm);
        font-weight: weight(semibold);
        @include margin-bottom(0.5);

        // &:after {
        //     content: "-";
        //     float: right;
        //     display: inline-block;
        //     font-size: font-size(xl);
        // }
    }
    > ul {
        @include clear-list;
        @include margin-bottom(0.5);
        > li {
            @include padding(0.1 0);
            &.divided { @include margin-top(1); }
            > i.fa { @include margin-right(0.5); }
            .a-count {
                float: right;
            }
            a {
                text-decoration: none;
                @include linkcolors($color-text, $color-primary);
                &:hover {
                    color: $color-primary-hover;
                }
            }
        }
    }

    .more-info {
        font-weight: weight(bold);
        text-decoration: none;
        &:before {
            @include margin-right(0.5);
            @include font-awesome($fa-var-minus);
        }
        &.active:before {
            @include font-awesome($fa-var-plus);
        }
    }
}


/** FOOTER */
footer {
    color: white;
    background: $color-primary;
    .footer-inner {
        @extend .container;
        @include padding(1 0);
        @include grid-container;

        .block {
            @include padding(1 0);
            @include grid-column(1, $layout-grid);

            border-bottom: 1px solid $color-border;
            &:last-child { border-bottom: 0; }

            h4 {
                @include padding-bottom(0.5);
                text-transform: uppercase;
                font-weight: weight(semibold);
                color: white;
            }
            a {
                @include linkcolors(white);
                &:hover { color: #eee; }
            }
            p:first-of-type { padding-top: 0; margin-top: 0; }
            ul,ol {
                @include clear-list;
            }

            .social-navigation {
                > li { font-size: font-size(l); }
            }
        }
        @include grid-media($layout-grid-tablet-up) {
            @include padding(2 0);
            .block {
                border: none;
                @include grid-column(3);
            }
        }
    }

    .footer-sub {
        @include grid-container;
        @include padding(1 0);
        background-color: darken($color-primary, 15%);
        text-align: center;
        @include grid-media($layout-grid-tablet-up) {
            .credits { text-align: right; }
            .legal { text-align: left; }
            .credits,
            .legal {
                @include grid-column(6);
            }
            a {
                @include linkcolors(white);
                &:hover { color: $color-primary; }
            }
        }
    }
}

/** PAGINATION */
.pager-wrapper {
    @include padding-top(1);

    ul.pagination {
        text-align: center;
        > li {
            display: inline-block;
            span,
            a {
                text-decoration: none;
                display: inline-block;
                border: 1px solid $color-primary;
                @include padding(0.3 0.5);
                @include margin(0 0.2);
            }
            a:hover {
                border-color: $color-primary-hover;
            }
            &.active span {
                background: $color-primary;
                color: white;
            }
        }
    }
}

/**
 * Custom pages
 */
#login-page {
    @include grid-container;
    @include grid-collapse;
    @include margin-bottom(2);

    .col {
        @include grid-column(1, $layout-grid);
        @include padding(1 0);

        &:first-child {
            border-bottom: 1px solid $color-border;
        }
    }

    h4 {
        @include typeset(heading-2);
        color: $color-primary;
    }

    .submit {
        @include margin(1 0);
        input { @extend .button.fluid; }
    }

    .block ul,
    .user-links {
        @include clear-list;
        > li{
            position: relative;
            margin-left: 20px;
            &:before {
                position: absolute;
                left: -20px;
                top: 6px;
                color: $color-primary;
                @include font-awesome($fa-var-angle-right);
            }
        }
    }

    .block {
        @include margin-bottom(1);
        ul > li {
            @include padding(0.2 0);
            &:before {
                @include font-awesome($fa-var-check-square);
            }
        }
    }

    @include grid-media($layout-grid-tablet-up) {
        @include grid-container;
        @include margin-top(1);

        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 0px;
            border-right: 1px solid $color-border;
            height: 100%;
            left: 50%;
        }

        .col {
            @include padding(1);
            @include grid-column(4);

            &:first-child {
                border-bottom: none;
                @include grid-push(2);
            }
        }
    }
}

/**
 * Wizard
 */

.wizard {
    @include clear-list;
    @include grid-container;
    @include grid-collapse;
    @include margin-bottom(1);

    li {
        @include grid-column(1, $layout-grid);
        @include padding(1);
        background: $color-bg-light;
        text-align: center;
        display: none;
        &.active {
            display: block;
            color: white;
            background: $color-primary;
        }
        .step {
            text-transform: uppercase;
            font-weight: weight(semibold);
            @include margin-right(0.2);
        }
        a {
            text-decoration: none;
        }
    }

    @include grid-media($layout-grid-tablet-up) {
        &.giftcard {
            li {
                display: block;
                @include grid-column(6);
            }
        }
        &.order {
            li {
                display: block;
                @include grid-column(3);
            }
        }
    }
}

div.submit,
.submit-wrapper {
    @include margin-bottom(1);
    text-align: right;

    .button {
        @include margin-left(0.2);
    }
}

/**
 * FORMS
 */
.form-columns {
    @include grid-container;
    @include grid-collapse;

    h3 {
        &:before {
            @include font-awesome($fa-var-angle-right);
            @include margin-right(0.5);
        }
    }

    .amount-list {
        @include clear-list;
        @include margin(1 0 0.5 0);
        li {
            display: inline;
            > a {
                margin-bottom: 0.5m;
                display: inline-block;
                background-color: $color-bg-light;
                border: 1px solid $color-border;
                @include padding(0.2 0.4);
                text-decoration: none;
                @include margin-right(0.1);

                &.active {
                    background-color: $color-primary;
                    color: white;
                }
            }
        }
    }

    .amount-outer {
        @include margin(0.5 0);
    }

    .divider {
        @include margin-top(1);
        @include margin-bottom(1);
        @include padding-top(1);
        border-top: 1px solid $color-border;
    }


    .street-info {
        @include grid-container;
        @include grid-collapse;
        .input {
            &:first-child {
                @include grid-column(8);
            }
            &:last-child {
                @include grid-column(4);
            }
        }
    }

    fieldset {
        @include margin-bottom(1);
    }

    .col {
        @include grid-column(1, $layout-grid);

        &:first-of-type {
            @include padding-right(0.5);
        }

        &:last-of-type {
            @include padding-left(0.5);
        }
    }

    @include grid-media($layout-grid-tablet-up) {
        .col {
            @include grid-column(6);
        }
    }
}


form {
    select,
    textarea,
    input[type="password"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="text"] {
        @include typeface(sans-serif);
        @include font-size(m);
        @include padding(0.5);
        background: $color-form-input;
        border: none;
        width: 100%;
        transition: background 100ms ease-in-out;
        border: 1px solid $color-primary;
        &:focus {
            outline: 0;
            background: darken($color-form-input, 5%);
            border-color: $color-primary-hover;
        }
    }

    .input {
        @include padding(0.4 0);
        label {
            font-weight: weight(semibold);
            @include margin-bottom(0.2);
        }

        select {
            border: 1px solid $color-primary;
        }

        &.radio,
        &.checkbox {
            input {
                float: left;
                @include margin(0.2 0.5 0 0);
            }
        }

        &.inline {
            @include grid-container;
            input {
                float: left;
                width: 48% !important;
                &:last-of-type { float: right; }
            }
        }

        .error-message {
            color: $color-error;
            font-weight: weight(semibold);
            @include padding(0.2 0);
        }

        &.amount {
            padding: 0;
            max-height: 0;
            overflow: hidden;
            transition: max-height 150ms ease-in-out;
            &.active {
                max-height: 80px;
            }
        }
    }

    .submit {
        input[type="submit"],
        button {
            @extend .button;
        }
    }

    #below-content &,
    .centered-form & {
        @include margin(1 0);

        .submit {
            @include padding(1 0);
            text-align: center;
        }

        fieldset {
            @include padding(2 0);
            border-bottom: 1px solid $color-border;

            &:first-of-type { padding-top: 0; }
            &:last-of-type { padding-bottom: 0; border: none; }
        }

        .input {
            @include grid-container;
            @include grid-media($layout-grid-tablet-up) {
                @include grid-collapse;
                $form-input-col: 8;

                &.password,
                &.text,
                &.number,
                &.email,
                &.select,
                &.tel,
                &.text {
                    label {
                        text-align: right;
                        @include padding-top(0.4);
                        @include grid-column(2);
                    }
                    select,
                    input {
                        @include grid-column($form-input-col);
                    }
                }

                &.select {
                    select {
                        @include margin-top(0.4);
                        @include margin-bottom(0.3);
                    }
                }

                &.date {
                    label {
                        text-align: right;
                        @include grid-column(2);
                    }
                    .selects {
                        select { width: auto; }
                        @include grid-column($form-input-col);
                    }
                }

                &.checkbox {
                    span {
                        @include grid-column($form-input-col);
                        @include grid-push(2);
                    }
                }

                .error-message {
                    @include grid-column($form-input-col);
                    @include grid-push(2);
                }
            }
        }
    }

    #below-content & {
        margin: 0;
    }
}

#shippingManagerFrame {
    min-height: 600px;
}

.form-columns {
    @include margin-bottom(1);
    .card-message {
        color: $color-error;
        &:before {
            @include font-awesome($fa-var-info-circle);
            @include margin-right(0.5);
        }
    }
    .discount-form-wrapper {
        position: relative;
        button {
            @extend .button;
            @include padding(0 1);
        }
        input {
            @include margin-bottom(0.5);
        }
    }

    .payment-methods {
        @include clear-list;
        li {
            position: relative;
            border: 1px solid $color-border;
            @include padding(1 1 1 2);
            img { width: auto; }
            .payment-method-label { display: none; }
            input[type="radio"] {
                position: absolute;
                left: 15px;
                top: 30px;
            }

            p {
                @include margin(0.2 0 0 0);
                padding: 0;
                &:before {
                    @include font-awesome($fa-var-info-circle);
                    @include margin-right(0.5);
                }
            }
        }
    }
}

.wishlist-header {
    @include margin-top(1);
    a {
        @include padding(0.5);
        @include margin-right(0.5);
        i { @include margin-right(0.2); }
        display: inline-block;
        border: 1px solid $color-border;
        text-decoration: none;
        font-size: font-size(ml);
    }
}

.store {
    .map,
    .visual {
        @include margin(1 0);
    }
    .map {
        min-height: 300px;
    }
    .info {
        background: $color-bg-light;
        @include padding(1);
    }
}

// FACET SLIDER
.facet-slider-wrapper {
    position: relative;
    margin: 0 15px;
    padding: 15px 0 30px 0;
    width: 95%;

    .indicators {
        height: 30px;
        .from { float: left; width: 40%; margin-left: -7px; }
        .to { float: right; width: 40%; text-align: right; margin-right: -7px; }
    }

    .ui-slider {
        height: 2px;
        background: $color-border;
        position: relative;

        .ui-slider-handle {
            height: 10px;
            width: 10px;
            border: 2px solid #fff;
            background: $color-primary;
            position: absolute;
            top: 50%;
            margin-top: -5px;
            margin-left: -7px;
            outline: none;
        }
        .ui-slider-range {
            top: 0;
            background: $color-primary;
            position: absolute;
            height: 2px;
        }
    }
}

/** mobile overlay */
#mobile-nav {
    background-color: rgba($color-primary, 0.95);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 800;
    transform: translateX(-100%);
    transition: transform 150ms ease-in-out;
    &.active {
        transform: translateX(0);
    }

    #mobile-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        ul {
            @include clear-list;
        }
        > ul {
            @include padding(1.5);
            font-size: font-size(l);
            > li {
                &:before {
                    @include font-awesome($fa-var-angle-right);
                    @include margin-right(0.6);
                }
                color: white;
                @include padding(0.3 0);
                a {
                    text-decoration: none;
                    @include linkcolors(white);
                }
                ul {
                    @include margin-left(1);
                    font-size: font-size(mll);
                    li {
                        @include padding(0.3 0);
                    }
                }
            }
        }
    }

    @include grid-media($layout-grid-tablet-up) {
        display: none;
    }
}

.toc {
    @include clear-list;
    @include margin(1 0);
    > li {
        @include font-size(ml);
        &:before {
            @include font-awesome($fa-var-angle-right);
            @include margin-right(0.5);
        }
    }
}

.faq-items {
    border-top: 1px solid $color-border;
    @include padding-top(1);
    dt {
        @extend h3;
    }
}
