// COLORS
$color-primary: #008394;
$color-primary-hover: darken($color-primary, 10%);
$color-text: #333333;
$color-text-light: #999;
$color-border: #e8e8e8;
$color-bg-contrast: #e9e9e9;
$color-bg-light: #e5f2f4;
$color-form-input: #f4f5f7;
$color-percentage-bubble: #9e005d;
$color-error: red;

// TYPO
@include font-face("Proxima Nova", "/fonts/proxima-nova/proximanova-regular-webfont", (eot svg ttf woff)) {
    font-style: normal;
    font-weight: 400;
};
@include font-face("Proxima Nova", "/fonts/proxima-nova/proximanova-semibold-webfont", (eot svg ttf woff)) {
    font-style: normal;
    font-weight: 600;
};
@include font-face("Proxima Nova", "/fonts/proxima-nova/proximanova-bold-webfont", (eot svg ttf woff)) {
    font-style: normal;
    font-weight: 700;
};

$base-unit: rem;
$base-font-size:    14px;
$base-line-height:  22px;
$line-height-method: rhythm;
$base-line-height-ratio: 1.5;
$print-font-size: 12pt;

$font-size: (
    xxl:  45px,
    xl:   32px,
    l:    25px,
    lm:   20px,
    mll:  18px,
    ml:   16px,
    m:    14px,
    s:    12px,
);

$font-weight: (
  bold:    700,
  semibold: 600,
  normal:  400
);

$typefaces: (
  sans-serif: (
    font-family: 'Proxima Nova',
    style: normal
  )
);

$typestyles: (
    nav: (
        weight: bold,
        case: uppercase
    ),
    blocklabel: (
        font-size: m,
        weight: bold,
        line-height: 1.5,
        case: uppercase
    ),
    banner-title: (
        font-size: xxl,
        weight: normal,
        case: uppercase
    ),
    button: (
      font-size: m,
      weight: bold,
      line-height: 1,
      case: uppercase
    ),
    heading-1: (
        font-size: xxl,
        weight: normal,
        line-height: 2
    ),
    heading-2: (
        font-size: l,
        weight: bold,
        line-height: 1.5
    ),
    heading-2-up: (
        font-size: l,
        weight: normal,
        line-height: 1.5,
        case: uppercase
    ),
    heading-3: (
        font-size: ml,
        weight: bold,
        line-height: 1.5
    ),
    heading-4: (
        font-size: m,
        weight: bold,
        line-height: 1.5,
    )
);

// Grid layouts
$max-width: 1200px;

$neat-grid: (
    columns: 12,
    gutter: 20px
);

$layout-grid: (
  columns: 1
);

$layout-product-grid: (
  columns: 2
);

$only-mobile: (
    columns: 4,
    media: "(max-width: 768px)"
);

$gutterless-grid: (
  columns: 6,
  gutter: 0
);

$gutterless-grid-tablet-up: (
  columns: 12,
  gutter: 0,
  media: "(min-width: 1024px)"
);

$layout-grid-tablet-up: (
  columns: 12,
  media: "(min-width: 768px)"
);

$layout-grid-tablet-landscape-up: (
  columns: 12,
  media: "(min-width: 1024px)"
);

$layout-grid-desktop-up: (
  columns: 12,
  media: "(min-width: #{$max-width})"
);
