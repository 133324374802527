@font-face {
	font-family: "iconfont";
	src: url('/fonts/iconfont/iconfont.eot');
	src: url('/fonts/iconfont/iconfont.eot?#iefix') format('eot'),
		url('/fonts/iconfont/iconfont.woff2') format('woff2'),
		url('/fonts/iconfont/iconfont.woff') format('woff'),
		url('/fonts/iconfont/iconfont.ttf') format('truetype'),
		url('/fonts/iconfont/iconfont.svg#iconfont') format('svg');
}

@mixin icon-styles {
	font-family: "iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == shop {
		$char: "\E001";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-shop {
	@include icon(shop);
}
